
import { currency } from "@/filters/utils";
import { Dealer, DealerTypes } from "@/store/modules/dealer/dealer.types";
import { Farmer, FarmerTypes } from "@/store/modules/farmers/farmers.types";
import {
  OrderStatsPayload,
  StatsState,
  StatTypes,
} from "@/store/modules/stats/stats.types";
import { Vue, Component } from "vue-property-decorator";
type HeaderStat = {
  title: string;
  total: number;
  icon: string;
  color: string;
  page: string;
  is_currency?: boolean;
};
import { namespace } from "vuex-class";

const farmer = namespace("Farmers");
const dealer = namespace("Dealer");
const Stats = namespace("Stats");

@Component({
  components: {
    FarmerList: () => import("@/components/farmer/FarmerList.vue"),
    AgroDealerList: () => import("@/components/dealer/AgroDealerList.vue"),
    OrdersChart: () => import("@/components/chart/OrdersChart.vue"),
    InputCategoryChart: () =>
      import("@/components/chart/InputCategoryChart.vue"),
  },
  filters: {
    currency,
  },
})
export default class DashboardView extends Vue {
  @farmer.State("farmers")
  public farmers!: Farmer[];

  // Stats
  @Stats.State("stats")
  public portalStats!: StatsState;

  @Stats.Action(StatTypes.GET_STATS)
  private getStats!: () => Promise<void>;

  @Stats.Action(StatTypes.GET_INPUT_CATEGORIES_STATS)
  private getInputStats!: () => Promise<void>;

  @Stats.Action(StatTypes.GET_ORDER_STATS)
  private getOrderStats!: (payload: OrderStatsPayload) => Promise<void>;

  @dealer.State("dealers")
  public dealers!: Dealer[];

  @farmer.Action(FarmerTypes.LOAD_FARMERS)
  public loadFarmers!: () => void;

  @dealer.Action(DealerTypes.LOAD_DEALERS)
  public loadDealers!: () => void;

  get statistics(): HeaderStat[] {
    return [
      {
        title: "Orders",
        total: this.portalStats.orders,
        icon: "mdi-shopping",
        color: "teal lighten-1",
        page: "farmers",
      },
      {
        title: "Farmers",
        total: this.portalStats.farmers,
        icon: "mdi-account",
        color: "pink lighten-1",
        page: "farmers",
      },
      {
        title: "Agro Dealers",
        total: this.portalStats.dealers,
        icon: "mdi-account-group-outline",
        color: "deep-orange lighten-1",
        page: "farmers",
      },
      {
        title: "Sales",
        total: this.portalStats.sales,
        icon: "mdi-cash-multiple",
        color: "purple lighten-1",
        page: "orders",
        is_currency: true,
      },
    ];
  }

  mounted(): void {
    this.getStats();
    this.getOrderStats({});
    this.getInputStats();
  }
}
