const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

// Filters
export function pad(value: number): string {
  if (!value) return "";
  return String(value).padStart(5, "0");
}

export function slashDate(value: number): string {
  if (!value) return "";
  const date = new Date(value * 1000);
  return `${String(date.getDate()).padStart(2, "0")}/${String(
    date.getMonth() + 1
  ).padStart(2, "0")}/${date.getFullYear()}`;
}

export function slash(date: Date): string {
  if (!date) return "";
  return `${String(date.getDate()).padStart(2, "0")}/${String(
    date.getMonth() + 1
  ).padStart(2, "0")}/${date.getFullYear()}`;
}

export function commaDate(value: number): string {
  if (!value) return "";
  const date = new Date(value * 1000);
  return `${date.getDate()} ${months[date.getMonth()]}, ${date.getFullYear()}`;
}

export function currency(value: number): string {
  if (!value) return "0";
  return value.toLocaleString();
}
